<template lang='pug'>
picture.on-order-square
  source(:srcset="`/images/on-order/${path}_140.webp, /images/on-order/${path}_280.webp 2x`", type="image/webp", media="(max-width: 520px)")
  source(:srcset="`/images/on-order/${path}_230.webp, /images/on-order/${path}_460.webp 2x`", type="image/webp", media="(max-width: 700px)")
  source(:srcset="`/images/on-order/${path}_320.webp, /images/on-order/${path}_640.webp 2x`", type="image/webp", media="(max-width: 950px)")
  source(:srcset="`/images/on-order/${path}_194.webp, /images/on-order/${path}_388.webp 2x`", type="image/webp", media="(max-width: 1240px)")
  source(:srcset="`/images/on-order/${path}_255.webp, /images/on-order/${path}_383.webp 1.5x `", type="image/webp")
  source(:srcset="`/images/on-order/${path}_255.jpg, /images/on-order/${path}_383.jpg 1.5x`")
  img.on-order-square__image(v-if="loadingLazy" :src="`/images/on-order/${path}_255.jpg`", :alt="alt", :title="alt", loading="lazy")
  img.on-order-square__image(v-else, :src="`/images/on-order/${path}_255.jpg`", :alt="alt", :title="alt", width="255", height="255")
</template>

<script>
export default {
  name: 'OnOrderImageSquare',
  props: {
    path: String,
    alt: String,
    loadingLazy: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang='sass'>
@import 'src/assets/styles/components/on-order-square'
</style>

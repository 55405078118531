<template lang='pug'>
head-meta(title-page="Наборы и фигурки из шоколада оптом на заказ — Музей «Шоколадный Выборг»",
  description="Заказать сувенирный шоколад, промо конфеты или корпоративные шоколадные наборы. Быстрая доставка. Индивидуальный дизайн. Короткие сроки изготовления"
)
on-order
contacts-line
</template>

<script>
import OnOrder from '../components/on-order/OnOrder'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'OnOrderPage',
  components: { ContactsLine, HeadMeta, OnOrder }
}
</script>

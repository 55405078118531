<template lang='pug'>
picture.on-order-rectangle
  source(:srcset="`/images/on-order/${path}_90.webp, /images/on-order/${path}_180.webp 2x`", type="image/webp", media="(max-width: 520px)")
  source(:srcset="`/images/on-order/${path}_150.webp, /images/on-order/${path}_300.webp 2x`", type="image/webp", media="(max-width: 700px)")
  source(:srcset="`/images/on-order/${path}_210.webp, /images/on-order/${path}_420.webp 2x`", type="image/webp", media="(max-width: 950px)")
  source(:srcset="`/images/on-order/${path}_144.webp, /images/on-order/${path}_288.webp 2x`", type="image/webp", media="(max-width: 1240px)")
  source(:srcset="`/images/on-order/${path}_190.webp, /images/on-order/${path}_285.webp 1.5x `", type="image/webp")
  source(:srcset="`/images/on-order/${path}_190.jpg, /images/on-order/${path}_285.jpg 1.5x`")
  img.on-order-square__image(v-if="loadingLazy", :src="`/images/on-order/${path}_190.jpg`", :alt="alt", :title="alt", loading="lazy", width="190", height="255")
  img.on-order-square__image(v-else, :src="`/images/on-order/${path}_190.jpg`", :alt="alt", :title="alt", width="190", height="255")
</template>

<script>
export default {
  name: 'OnOrderImageSquare',
  props: {
    path: String,
    alt: String,
    loadingLazy: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang='sass'>
@import 'src/assets/styles/components/on-order-rectangle'
</style>
